import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  CardContent,
  Grid,
  Link,
  // Button as MuiButton,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography as MuiTypography,
  Box,
} from "@mui/material";
import { spacing, display } from "@mui/system";
// import { PayLaterSvg } from "../icons/PayLaterSvg";
// import "@lendica/paylaterbutton";

import { useLendicaContext } from "../../hooks/useLendicaContext";

const Card = styled(MuiCard)`
  ${spacing};

  box-shadow: none;
`;

const Divider = styled(MuiDivider)(spacing);

const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
`;

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

// const Button = styled(MuiButton)(spacing);

const Typography = styled(MuiTypography)(display);

// const stashData = {
//   total: 790,
//   partner_name: "stash_ok",
//   partner_invoice_uuid: "157234A8-8CDB-4FE4-9BF3-117956CCB7E3",
// };

function InvoiceDetails() {
  const { lendica } = useLendicaContext();

  useEffect(() => {
    if (lendica.current) {
      lendica.current.button.destroy();
      lendica.current.paylater
        .renderButton({
          containerElement: document.getElementById("paylater-container"),
          showAsLowAs: true,
          children: "Pay this invoice later",
        })
        .then(() => {
          lendica.current.paylater({
            total: 5000.0,
            partner_name: "demo",
            partner_invoice_uuid: "7",
          });
        });
    }
  }, [lendica]);

  return (
    <React.Fragment>
      <Helmet title="Invoice Details" />

      <Typography variant="h3" gutterBottom display="inline">
        Invoice #000112
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/">
          Pages
        </Link>
        <Link component={NavLink} to="/">
          Invoices
        </Link>
        <Typography>Details</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container justifyContent="center">
        <Grid item xs={12} lg={10}>
          <Shadow>
            <Card px={6} pt={6}>
              <CardContent>
                <Grid container spacing={6}>
                  <Grid item xs={12}>
                    <Typography variant="body2" gutterBottom>
                      Hello Anna Walley,
                      <br />
                      This is the receipt for a payment of $268.85 (USD) you
                      made to Lendica Test Corp.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="caption">Payment No.</Typography>
                    <Typography variant="body2">741037024</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="caption" align="right" display="block">
                      Payment Date
                    </Typography>
                    <Typography variant="body2" align="right">
                      January 2, 2021 - 03:45 pm
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="caption">Client</Typography>
                    <Typography variant="body2">
                      Anna Walley
                      <br />
                      4183 Forest Avenue
                      <br />
                      New York City
                      <br />
                      10011
                      <br />
                      USA
                      <br />
                      <Link href="mailto:anna@walley.com">anna@walley.com</Link>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="caption" align="right" display="block">
                      Payment To
                    </Typography>
                    <Typography variant="body2" align="right">
                      Lendica Test LLC
                      <br />
                      354 Roy Alley
                      <br />
                      Denver
                      <br />
                      80202
                      <br />
                      USA
                      <br />
                      <Link href="mailto:info@material-app.com">
                        info@material-app.com
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Card px={6}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Description</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell align="right">Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Material App Theme Customization
                    </TableCell>
                    <TableCell>2</TableCell>
                    <TableCell align="right">$1,500.00</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Monthly Subscription
                    </TableCell>
                    <TableCell>3</TableCell>
                    <TableCell align="right">$2,500.00</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Additional Service
                    </TableCell>
                    <TableCell>2</TableCell>
                    <TableCell align="right">$1,000.00</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell />
                    <TableCell>Subtotal</TableCell>
                    <TableCell align="right">$5,000.00</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell />
                    <TableCell>Shipping</TableCell>
                    <TableCell align="right">$0.00</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell />
                    <TableCell>Discount</TableCell>
                    <TableCell align="right">0%</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell />
                    <TableCell>Total</TableCell>
                    <TableCell align="right">$5,000.00</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Card>
            <Card pb={6} px={6}>
              <CardContent style={{ textAlign: "center" }}>
                <div>
                  <Typography
                    variant="caption"
                    paragraph
                    gutterBottom
                    align="center"
                  >
                    Extra note: Please send all items at the same time to the
                    shipping address. Thanks in advance.
                  </Typography>
                </div>
                {/* <Link
                  sx={{ mt: 2 }}
                  href="https://sandbox.golendica.com/api/v1/pod/paylater/partner_paylater_redirect/?partner=apexdemo&invoice_id=fc5204ce-f758-4372-901a-966feec0bf73"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <PayLaterSvg height={36} />
                </Link> */}
                <Box
                  id="paylater-container"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  {/* <paylater-button
                    onClick={() =>
                      window.open(
                        "https://paylater.app-idev.golendica.com/?partner_name=apexdemo&partner_invoice_uuid=db9260e5-56e7-44a0-8e90-a49b25e983a6",
                        "_blank"
                      )
                    }
                  ></paylater-button> */}
                </Box>
              </CardContent>
            </Card>
          </Shadow>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default InvoiceDetails;
